<template>
<div class="selfcheck-container" id="js_selfcheck_container">
	<article class="selfcheck meteoropath">
		<h1 class="selfcheck__header" id="js_observer">
			<img src="@/assets/img/selfcheck-meteoropathy/title01.png" alt="あなたは大丈夫？ 医師監修 気象病セルフチェック">
			<p><span>presented by</span><i class="selfcheck__header--icon sprite_meteoropathy logo" /></p>
		</h1>

		<!-- 紹介 -->
		<section class="selfcheck__intro">
			<h2 class="selfcheck__intro--title"><i class="sprite_meteoropathy intro" /></h2>
			<p class="selfcheck__intro--text">雨の日に頭痛やだるさを感じたことはありますか？天気の崩れや環境の変化に自分の身体がついていけず、自分の弱いところが出てできてしまう病を<span class="is-emphasis">「気象病」</span>といいます。<br>
			原因不明の体調不良がある方、もしかしたら気象病かもしれません。この<span class="is-emphasis">「気象病セルフチェック」</span>で当てはまるのか、みてみましょう。（質問は全12問あります。）<br>
			<span class="is-small">監修:せたがや内科・神経内科クリニック 久手堅 司先生</span></p>
		</section>
		<!-- /紹介 -->

		<!-- 気象病セルフチェック -->
		<section class="selfcheck__questions">
			<ul class="selfcheck__questions--list">
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.1</span>
					<p class="selfcheck__questions--text">天候が変わる時に体の不調やメンタルの不調がある。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q01" :value="5" v-model="question[0]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q01" :value="0" v-model="question[0]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.2</span>
					<p class="selfcheck__questions--text">雨が降る前や天候が変わる前に、何となく予測が出来る。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q02" :value="4" v-model="question[1]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q02" :value="0" v-model="question[1]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.3</span>
					<p class="selfcheck__questions--text">頭痛持ちである。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q03" :value="3" v-model="question[2]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q03" :value="0" v-model="question[2]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.4</span>
					<p class="selfcheck__questions--text">肩（首）こりがある。猫背、そり腰があり、姿勢が悪い。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q04" :value="1" v-model="question[3]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q04" :value="0" v-model="question[3]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.5</span>
					<p class="selfcheck__questions--text">歯のくいしばりや、歯ぎしり、歯の治療が多い。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q05" :value="1" v-model="question[4]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q05" :value="0" v-model="question[4]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.6</span>
					<p class="selfcheck__questions--text">めまいや耳鳴りが起こりやすい。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q06" :value="1" v-model="question[5]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q06" :value="0" v-model="question[5]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.7</span>
					<p class="selfcheck__questions--text">乗り物酔いをしやすい。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q07" :value="1" v-model="question[6]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q07" :value="0" v-model="question[6]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.8</span>
					<p class="selfcheck__questions--text">１日4時間以上、PCやスマートフォンを使う日が多い。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q08" :value="1" v-model="question[7]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q08" :value="0" v-model="question[7]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.9</span>
					<p class="selfcheck__questions--text">ストレッチや柔軟体操・運動をすることが少ない。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q09" :value="1" v-model="question[8]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q09" :value="0" v-model="question[8]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.10</span>
					<p class="selfcheck__questions--text">年中エアコンが効いている環境にいることが多い。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q10" :value="1" v-model="question[9]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q10" :value="0" v-model="question[9]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.11</span>
					<p class="selfcheck__questions--text">日常的にストレスを感じている。特に精神的なストレス。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q11" :value="1" v-model="question[10]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q11" :value="0" v-model="question[10]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.12</span>
					<p class="selfcheck__questions--text">更年期障害（男女ともに）ではないかと思うことがある。</p>
					<div class="selfcheck__questions--label">
						<label>
							<input type="radio" name="q12" :value="1" v-model="question[11]">
							<i class="selfcheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q12" :value="0" v-model="question[11]">
							<i class="selfcheck__questions--button" />
						</label>
					</div>
				</li>
			</ul>
		</section>
		<!-- /気象病セルフチェック -->

		<!-- アウトロ -->
		<nav class="selfcheck__outro">
			<!-- 結果をみる -->
			<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckMeteoropathyRes`, _result: String(computedRequltParam), _answer: computedAnswerParam, _previousPage: previousPage } })" id="trackingSelfcheckResultBtn01" :class="[
				'selfcheck__outro--submit',
				{ 'is-eabled': computedEabledflag }
			]">結果をみる</a>
			<!-- /結果をみる -->

			<!-- 特集にもどる -->
			<a v-if="previousPage === 'rainyseason2023'" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'rainyseason2023' } })" class="selfcheck__outro--lptop">梅雨特集TOPへもどる</a>
			<!-- /特集にもどる -->

			<!-- TOPにもどる -->
			<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="selfcheck__outro--sitetop">頭痛ーるTOPへ</a>
			<!-- /TOPにもどる -->
		</nav>
		<!-- /アウトロ -->

		<!-- 上部に戻るボタン -->
		<nav class="selfcheck__pagetop" id="js_pagetop_btn">
			<a href="javascript:void(0);" @click.prevent="onScroll('js_selfcheck_container')" class="selfcheck__pagetop--link">
				<img src="~@/assets/img/pagetop_btn01.png" alt="ページTOPにもどる">
			</a>
		</nav>
		<!-- /上部に戻るボタン -->
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'SelfcheckMeteoropathy',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			question: [],
			pageTopObserver: null,
			previousPage: ''
		}
	},
	props: {
	},
	computed: {
		computedRequltParam () {
			const resultParam = this.question.reduce((accumulator, currentValue) => {
				return accumulator + currentValue
			}, 0)
			return resultParam >= 5 ? 2 : resultParam >= 3 ? 1 : 0
		},

		computedAnswerParam () {
			return this.question.map((elem, index) => {
				return elem ? index + 1 : 0
			}).filter(item => item !== 0).join(',')
		},

		computedEabledflag () {
			return this.question.filter(item => typeof item !== 'undefined').length === 12
		}
	},
	mounted () {
		// ページTOPに戻るボタン表示の処理
		const $target = document.getElementById('js_observer')
		const $pagetopBtn = document.getElementById('js_pagetop_btn')
		this.pageTopObserver = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				$pagetopBtn.classList.remove('is-show')
			} else {
				$pagetopBtn.classList.add('is-show')
			}
		})
		this.pageTopObserver.observe($target)

		// $route.queryの_previousPageがあれば特集ページにもどるボタンを出しわけする
		if (this.$route.query._previousPage) this.previousPage = this.$route.query._previousPage
	},
	beforeDestroy () {
		// IntersectionObserverの監視を停止
		this.pageTopObserver.disconnect()
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-3: 0.187rem;
$spacing-13: 0.812rem;
$spacing-14: 0.875rem;
$spacing-17: 1.062rem;
$spacing-25: 1.562rem;
$spacing-30: 1.875rem;
$spacing-40: 2.5rem;
$spacing-50: 3.125rem;
$spacing-54_5: 3.406rem;
$font-size-13: 0.812rem;
$font-size-15: 0.937rem;
$font-size-37: 2.3125rem;
$selfcheck-primary: #0071DB;
$selfcheck-submit-background: #FF4D4D;
$selfcheck-submit-border: #B43333;
$selfcheck-submit-disabled-border: #999999;
$selfcheck-lptop-background: #FF61A0;
$selfcheck-lptop-border: #E6337B;
$line-height-1_2: 1.2;

.selfcheck {
	padding: 0;

	&__intro {
		margin: $spacing-20 0 0 0;
		font-size: $font-size-15;
		color: $text-primary;

		&--title {
			margin: 0;
			overflow: hidden;
			text-align: center;
			font-weight: normal;

			& > i { margin: 0 auto; }
		}

		&--text {
			font-family: $lp-family-primary;
			margin: $spacing-13 0 0 0;
			padding: 0 $spacing-14;
			text-align: justify;

			.is-emphasis {
				color: $selfcheck-primary;
			}

			.is-small {
				font-size: $font-size-13;
			}
		}
	}

	&__questions {
		$parent: &;
		width: 92.5334%;
		margin: $spacing-40 auto 0;

		&--list {
			margin: 0;
			padding: 0;
		}

		&--item {
			list-style: none;
			font-family: $lp-family-secondary;
			border-bottom: 1px solid $line-primary;
			margin-bottom: $spacing-30;
			padding: 0 $spacing-25 $spacing-30;
		}

		&--heading {
			display: block;
			color: $selfcheck-primary;
			font-family: $lp-family-tertiary;
			font-size: $font-size-37;
			text-align: center;
		}

		&--text {
			font-size: $font-size-18;
			margin: $spacing-10 0 0;
		}

		&--label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: $spacing-20;

			label {
				input[type="radio"] {
					display: none;
				}

				input[type="radio"] + #{$parent}--button {
					background-image: url(~@/assets/img/selfcheck-meteoropathy/sprite_inp.png);
					background-size: (372px / 2) (570px / 2);
					background-repeat: no-repeat;
					display: block;
					background-position: 0 0;
					width: (260px / 2);
					height: (80px / 2);
					@media (max-width: (360px)) {
						background-size: (314px / 2) (482px / 2);
						width: (220px / 2);
						height: (68px / 2);
					}
				}

				&:nth-of-type(odd) {
					input[type="radio"] + #{$parent}--button {
						background-position: 0 0;
					}

					input[type="radio"]:checked + #{$parent}--button {
						background-position: 0 (-80px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-68px / 2);
						}
					}
				}

				&:nth-of-type(even) {
					input[type="radio"] + #{$parent}--button {
						background-position: 0 (-160px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-135px / 2);
						}
					}

					input[type="radio"]:checked + #{$parent}--button {
						background-position: 0 (-240px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-203px / 2);
						}
					}
				}
			}
		}
	}

	&__outro {
		width: 100%;
		margin: $spacing-50 auto 0;
		box-sizing: border-box;

		& > a {
			display: block;
			box-sizing: border-box;
			text-decoration: none;
			text-align: center;
			color: $text-seconday;
		}

		&--submit {
			width: 80%;
			margin: 0 auto;
			padding: $spacing-17 0;
			background: $button-secondary;
			border-radius: $spacing-6;
			font-size: $font-size-24;
			box-shadow: 0 $spacing-3 $selfcheck-submit-disabled-border;
			pointer-events: none;

			@media (max-width: (374px)) { font-size: $font-size-20; }
			@media (max-width: (330px)) { font-size: $font-size-18; }

			&.is-eabled {
				background: $selfcheck-submit-background;
				box-shadow: 0 $spacing-3 $selfcheck-submit-border;
				pointer-events: auto;
			}
		}

		&--lptop {
			width: 80%;
			margin: $spacing-50 auto 0;
			padding: $spacing-10 0;
			font-family: $lp-family-secondary;
			font-size: $font-size-18;
			line-height: $line-height-1_2;
			background-color: $selfcheck-lptop-background;
			border-radius: $spacing-6;
			box-shadow: 0 $spacing-3 $selfcheck-lptop-border;
		}

		&--sitetop {
			font-family: $lp-family-secondary;
			width: 61.3334%;
			margin: $spacing-30 auto 0;
			padding: $spacing-10 0;
			border-radius: $spacing-26;
			font-size: $font-size-24;
			background-color: $primary;
		}
	}

	/* ページTOPに戻るボタン */
	&__pagetop {
		position: sticky;
		width: 100%;
		margin: -$spacing-54_5 auto 0;
		padding: 0 $spacing-14;
		box-sizing: border-box;
		bottom: 0;
		opacity: 0;
		transition: opacity .3s ease-in;
		pointer-events: none;

		&.is-show { opacity: 1; }

		&--link {
			display: block;
			width: 14.1211%;
			margin-left: auto;
			pointer-events: auto;

			& > img {
				display: block;
				width: 100%;
			}
		}
	}

	.sprite_meteoropathy {
		background-image: url(~@/assets/img/selfcheck-meteoropathy/sprite_inp.png);
		background-size: (372px / 2) (570px / 2);
		background-repeat: no-repeat;
		display: block;

		&.intro {
			width: (372px / 2);
			height: (210px / 2);
			background-position: 0 (-320px / 2);
		}

		&.logo {
			width: (157px / 2);
			height: (40px / 2);
			background-position: 0 (-530px / 2);
		}
	}
}
</style>
